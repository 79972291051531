import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppContext } from './AppContext';

export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;

const setUsuario = (usuario) => {
    edoGlobal.usuario = usuario;
  }
const edoGlobal = { 
    nombreEmpresa: 'MINERA INDE',
    urlws:'', //'http://localhost:27190/',
    setUsuario
};
ReactDOM.render(
    <AppProvider value={edoGlobal}>
      <App />
    </AppProvider>    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
