import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Spinner, Input, FormGroup, Button } from 'reactstrap';
import { AppContext } from './AppContext';
import {Tabla} from './Tabla';
import Swal from 'sweetalert2'
import { Panel } from './Panel';

export const Comedores = () =>{
    const edoGlobal = useContext(AppContext);
    let [comedores,setComedores] = useState([]);
    let [idComedor,setIdComedor] = useState(0);
    let [nombre,setNombre] = useState("");
    const [cargando,setCargando] = useState(false);
    const [editando,setEditando] = useState(false);

    useEffect(() => {
        (async () => {
          cargarLista();
        })();
      }, []);
    async function cargarLista(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/comedor/lista/${edoGlobal.usuario.Empresa}`;
        let resp = await fetch(url);
        const comedores = await resp.json();
        setCargando(false);
        setComedores(comedores);
    }
    function nuevo(){
        setIdComedor(0);
        setEditando(true);
    } 
    const editar = i =>{
        setIdComedor(comedores[i].I);
        setEditando(true);
    }
    const eliminar = i =>{
    }
    const cerrarFormulario = (cargar) =>{
        setEditando(false);
        if(cargar)
           cargarLista();
    }
    const estilo1 = { display: editando ? 'none' : 'inline' };
    const estilo2 = { display: editando ? 'inline' : 'none' };
    return (
      <div>
        <div style={estilo2}>
          <Comedor idComedor={idComedor} cerrar={cerrarFormulario} />
        </div>
        <Container style={estilo1}>
          <div className="row">
            <div className="col-4">
              <FormGroup>
                <span>Nombre</span>
                <Input
                  size="sm"
                  type="text"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-1">
              <br />
              <Button outline color="success" size="sm" onClick={cargarLista}>
                <img src="imagenes/zoom.png" alt="buscar" />
              </Button>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <Button outline color="success" size="sm" onClick={nuevo}>
                <img src="imagenes/add.png" alt="nuevo" />
              </Button>
            </div>
          </div>
          <Tabla
            campos={["N"]}
            cols={["Nombre"]}
            tams={[300, 30, 30]}
            datos={comedores}
            opciones={[
              { tit: "editar", img: "edit", fn: editar },
              { tit: "eliminar", img: "trash", fn: eliminar },
            ]}
          />
        </Container>
        {cargando ? (
          <div className="loader">
            <Spinner animation="border" color="warning" />
          </div>
        ) : null}
      </div>
    );
}

export const Comedor = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [Nombre,setNombre] = useState("");
    let [NombreCorto,setNombreCorto] = useState("");
    let [Direccion,setDireccion] = useState("");
    let [Telefono,setTelefono] = useState("");
    let [DesayunoHoraIni,setDesayunoHoraIni] = useState("");
    let [ComidaHoraIni,setComidaHoraIni] = useState("");
    let [CenaHoraIni,setCenaHoraIni] = useState("");
    let [DesayunoHoraFin,setDesayunoHoraFin] = useState("");
    let [ComidaHoraFin,setComidaHoraFin] = useState("");
    let [CenaHoraFin,setCenaHoraFin] = useState("");
    let [CostoDesayuno,setCostoDesayuno] = useState(0);
    let [CostoComida,setCostoComida] = useState(0);
    let [CostoCena,setCostoCena] = useState(0);
    let [cargando,setCargando] = useState(false);

    useEffect(() => {
        (async () => {
            setCargando(true);
            let url = `${edoGlobal.urlws}/comedor/cargar/${props.idComedor}`;
            let resp = await fetch(url);
            const a = await resp.json();
            setCargando(false);
            setNombre(a.Nombre);
            setNombreCorto(a.NombreCorto);
            setTelefono(a.Telefono);
            setDireccion(a.Direccion);
            setDesayunoHoraIni(a.DesayunoHoraIni);
            setComidaHoraIni(a.ComidaHoraIni);
            setCenaHoraIni(a.CenaHoraIni);
            setDesayunoHoraFin(a.DesayunoHoraFin);
            setComidaHoraFin(a.ComidaHoraFin);
            setCenaHoraFin(a.CenaHoraFin);
            setCostoDesayuno(a.CostoDesayuno);
            setCostoComida(a.CostoComida);
            setCostoCena(a.CostoCena);
        })();
    }, [props.idComedor]);

    async function guardar(){
        let Empresa = edoGlobal.usuario.Empresa;
        let FechaReg = "";
        const dato = {
            Id : props.idComedor,
            Empresa,Nombre, NombreCorto, Telefono, Direccion,
            DesayunoHoraIni, ComidaHoraIni, CenaHoraIni, 
            DesayunoHoraFin,ComidaHoraFin, CenaHoraFin,
            CostoDesayuno, CostoComida, CostoCena
        }
        let url = `${edoGlobal.urlws}/comedor/guardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            props.cerrar(true);
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    function cancelar(){
        props.cerrar(false);
    }
    return (
      <Panel titulo="Comedor">
        <div className="row">
          <div className="col-10">
            <FormGroup>
              <span>Nombre</span>
              <Input size="sm" type="text" value={Nombre} onChange={(e) => setNombre(e.target.value)} />
            </FormGroup>
          </div>
          <div className="col-2">
            <FormGroup>
              <span>Nombre Corto</span>
              <Input size="sm" type="text" value={NombreCorto} onChange={(e) => setNombreCorto(e.target.value)} />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FormGroup>
              <span>Direccion</span>
              <Input size="sm" type="text" value={Direccion} onChange={(e) => setDireccion(e.target.value)} />
            </FormGroup>
          </div>
          <div className="col-2">
            <FormGroup>
              <span>Telefono</span>
              <Input size="sm" type="text" value={Telefono} onChange={(e) => setTelefono(e.target.value)} />
            </FormGroup>
          </div>
          <div className="col-1">
            <FormGroup>
              <span>$ Desayuno</span>
              <Input size="sm" type="text" value={CostoDesayuno} onChange={(e) => setCostoDesayuno(e.target.value)} />
            </FormGroup>
          </div>
          <div className="col-1">
            <FormGroup>
              <span>$ Comida</span>
              <Input size="sm" type="text" value={CostoComida} onChange={(e) => setCostoComida(e.target.value)} />
            </FormGroup>
          </div>
          <div className="col-1">
            <FormGroup>
              <span>$ Cena</span>
              <Input size="sm" type="text" value={CostoCena} onChange={(e) => setCostoCena(e.target.value)} />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <table>
              <tr>
                <td>Desayuno de</td>
                <td><Input size="sm" type="time" value={DesayunoHoraIni} onChange={(e) => setDesayunoHoraIni(e.target.value)} /></td>
                <td>a</td>
                <td><Input size="sm" type="time" value={DesayunoHoraFin} onChange={(e) => setDesayunoHoraFin(e.target.value)} /></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Comida de</td>
                <td><Input size="sm" type="time" value={ComidaHoraIni} onChange={(e) => setComidaHoraIni(e.target.value)} /></td>
                <td>a</td>
                <td><Input size="sm" type="time" value={ComidaHoraFin} onChange={(e) => setComidaHoraFin(e.target.value)} /></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Cena de</td>
                <td><Input size="sm" type="time" value={CenaHoraIni} onChange={(e) => setCenaHoraIni(e.target.value)} /></td>
                <td>a</td>
                <td><Input size="sm" type="time" value={CenaHoraFin} onChange={(e) => setCenaHoraFin(e.target.value)} /></td>
              </tr>
            </table>
          </div>
        </div>
        <br/><br/><br/>
        <Button onClick={guardar} size="sm" outline color="success">Guardar</Button>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <Button onClick={cancelar} size="sm" outline color="danger">Cancelar</Button>
        {cargando ? (
          <div className="loader">
            <Spinner animation="border" color="warning" />
          </div>
        ) : null}
      </Panel>
    );
}