import React from "react";
import { Table } from "reactstrap";

export const Tabla = ({id, campos, cols, tams, datos, opciones = [] }) => {
    const imgs = opciones.map((o, i) => "/imagenes/" + o.img + ".png");
    return (
        <Table size="sm" striped hover>
            <thead>
                <tr>
                    {cols.map((e, i) => {
                        let est = tams[i] > 0 ? { width: tams[i] } : {};
                        return (
                            <th key={"r" + i} style={est}>
                                {e}
                            </th>
                        )
                    })
                    }
                    {
                        opciones.map((o, i) => <th key={"c" + i} style={{ width: 30, textAlign: 'center' }}></th>)
                    }
                </tr>
            </thead>
            <tbody>
                {datos.map((e, i) => {
                    return (
                        <tr key={i}>
                            {campos.map((col, j) => {
                                return (
                                    <td key={"d" + j}>
                                        {e[col]}
                                    </td>
                                )
                            })}
                            {
                                opciones.map((o, k) => <td key={"j" + k}><img src={imgs[k]} alt={o.tit} title={o.tit} onClick={() => opciones[k].fn(i)} /></td>)
                            }
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}