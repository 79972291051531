import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Spinner, Input, FormGroup, Button } from 'reactstrap';
import { AppContext } from './AppContext';
import {Tabla} from './Tabla';
import Swal from 'sweetalert2'
import { Panel } from './Panel';

export const Empleados = () =>{
    const edoGlobal = useContext(AppContext);
    let [empleados,setEmpleados] = useState([]);
    let [tipos,setTipos] = useState([]);
    let [idEmp,setIdEmp] = useState(0);
    let [nombre,setNombre] = useState("");
    const [cargando,setCargando] = useState(false);
    const [editando,setEditando] = useState(false);

    useEffect(() => {
        (async () => {
            cargarTipos();
            cargarLista();
        })();
      }, []);
    async function cargarLista(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/empleado/lista/${edoGlobal.usuario.Empresa}`;
        let resp = await fetch(url);
        const empleados = await resp.json();
        setCargando(false);
        setEmpleados(empleados);
    }
    async function cargarTipos(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/empleado/tipos/${edoGlobal.usuario.Empresa}`;
        let resp = await fetch(url);
        const tipos = await resp.json();
        setCargando(false);
        setTipos(tipos);
    }
    async function nuevo(){
        const { value: numEmp } = await Swal.fire({
            title: 'Agregar empleado',
            input: 'text',
            inputLabel: 'Numero del empleado',
            inputValue: '',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'Debes ingresar el numero de empleado'
              }
              if (isNaN(value)) {
                return 'Debes ingresar el numero de empleado'
              }
            }
          })
          
          if (numEmp) {
            setIdEmp(Number(numEmp));
            setEditando(true);
        }
    } 
    const editar = i =>{
        setIdEmp(empleados[i].I);
        setEditando(true);
    }
    const eliminar = i =>{
    }
    const cerrarFormulario = (cargar) =>{
        setEditando(false);
        if(cargar)
           cargarLista();
    }
    const estilo1 = { display: editando ? 'none' : 'inline' };
    const estilo2 = { display: editando ? 'inline' : 'none' };
    return (
      <div>
        <div style={estilo2}>
          <Empleado idEmp={idEmp} tipos={tipos} cerrar={cerrarFormulario} />
        </div>
        <Container style={estilo1}>
          <div className="row">
            <div className="col-4">
              <FormGroup>
                <span>Nombre</span>
                <Input
                  size="sm"
                  type="text"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-1">
              <br />
              <Button outline color="success" size="sm" onClick={cargarLista}>
                <img src="imagenes/zoom.png" alt="buscar" />
              </Button>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <Button outline color="success" size="sm" onClick={nuevo}>
                <img src="imagenes/add.png" alt="nuevo" />
              </Button>
            </div>
          </div>
          <Tabla
            campos={["N"]}
            cols={["Nombre"]}
            tams={[300, 30, 30]}
            datos={empleados}
            opciones={[
              { tit: "editar", img: "edit", fn: editar },
              { tit: "eliminar", img: "trash", fn: eliminar },
            ]}
          />
        </Container>
        {cargando ? (
          <div className="loader">
            <Spinner animation="border" color="warning" />
          </div>
        ) : null}
      </div>
    );
}

export const Empleado = (props) =>{
    const edoGlobal = useContext(AppContext);
    let [Nombre,setNombre] = useState("");
    let [Tipo,setTipo] = useState(0);
    let [cargando,setCargando] = useState(false);

    useEffect(() => {
        (async () => {
            setCargando(true);
            let url = `${edoGlobal.urlws}/empleado/cargar/${props.idEmp}?emp=${edoGlobal.usuario.Empresa}`;
            let resp = await fetch(url);
            const a = await resp.json();
            setCargando(false);
            setNombre(a.Nombre);
            setTipo(a.Tipo);
        })();
    }, [props.idEmp]);

    async function guardar(){
        let Empresa = edoGlobal.usuario.Empresa;
        let FechaReg = "";
        const dato = {
            Id : props.idEmp,
            Empresa,Nombre, Tipo
        }
        let url = `${edoGlobal.urlws}/empleado/guardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            props.cerrar(true);
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    function cancelar(){
        props.cerrar(false);
    }
    return (
      <Panel titulo="Empleado">
        <div className="row">
          <div className="col-10">
            <FormGroup>
              <span>Nombre</span>
              <Input size="sm" type="text" value={Nombre} onChange={(e) => setNombre(e.target.value)} />
            </FormGroup>
          </div>
          <div className="col-3">
              <FormGroup>
                <span>Tipo</span>
                <Input type="select" size="sm" value={Tipo} onChange={(e) => setTipo(e.target.value)} >
                  <option value={0}>Especifique</option>
                  {props.tipos.map((i, k) => (
                    <option key={i.I} value={i.I}>
                      {i.N}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </div>
        </div>
        <br/><br/><br/>
        <Button onClick={guardar} size="sm" outline color="success">Guardar</Button>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <Button onClick={cancelar} size="sm" outline color="danger">Cancelar</Button>
        {cargando ? (
          <div className="loader">
            <Spinner animation="border" color="warning" />
          </div>
        ) : null}
      </Panel>
    );
}